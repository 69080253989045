import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Box, Divider } from "@mui/material";
import { Main } from "layouts";
import HeroHeader from "components/HeroHeader";
import HighlightBlogContainer from "components/HighlightBlogContainer";
import AllBlogs from "components/AllBlogs";
import createClient from "../client/sanity";

const Blogs = ({ blogArticles }) => {
  const [shownArticles, setShownArticles] = useState(blogArticles.slice(4, 10));
  const [nextArticlesSet, setNextArticlesSet] = useState([]);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);

  // Function to handle blog click
  const onArticleClick = (slug) => {
    navigate(`/blogs/${slug}`);
  };

  // Function to load next array of Articles
  const getNewBlogs = async () => {
    const data = await fetchSanityData(shownArticles[shownArticles.length - 1].createdAt);
    if (data.length === 0) {
      setShowLoadMoreButton(false);
    }
    setNextArticlesSet(data);
    return data;
  };

  // Function to load more articles
  const loadMoreArticles = async () => {
    setShownArticles((prevArticles) => {
      return [...prevArticles, ...nextArticlesSet];
    });
  };

  // useEffect to load new articles and set perviously loaded to the current state
  useEffect(() => {
    getNewBlogs();
  }, [shownArticles]);

  const fetchSanityData = async (lastArticleCreatedAt) => {
    try {
      const data = await createClient.fetch(
        `*[_type == "article" && articleType == "BlogPost" && _createdAt < "${lastArticleCreatedAt}" ]{
          "imageUrl": image.asset->url,
          "title": headline,
          "createdAt": _createdAt,
          "date": _createdAt,
          "description": description,
          "slug": slug.current,
          "tag": tags
          }`
      );
      return data;
    } catch (error) {
      // console.log("Error Fetching Data", error);
    }
  };

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main>
        <HeroHeader
          title="Stories & Ideas"
          description="Strategic Insights To Propel Your Business Forward"
          Component={() => <Divider sx={{ borderColor: "#2984FF", mt: 10 }} />}
          highlightText={{
            title: {
              text: "Ideas",
            },
          }}
        />
        <HighlightBlogContainer highlightArticles={blogArticles.slice(0, 4)} onArticleClick={onArticleClick} />
        <AllBlogs
          blogArticles={shownArticles}
          onArticleClick={onArticleClick}
          loadMoreArticles={loadMoreArticles}
          showLoadMoreButton={showLoadMoreButton}
        />
      </Main>
    </Box>
  );
};

// Exporting Blogs component
export default Blogs;
