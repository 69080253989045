import { Box, Stack, Typography, useTheme, Skeleton, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import letterHighlight from "../images/hero-header/highlightLetter.svg";
import bgUrl from "../images/hero-header/staticBackground.svg";
import PortableTextComponent from "./PortableTextComponent";
import { Helmet } from "react-helmet";
import SimilarStoriesCard from "./SimilarStoriesCard";
import createClient from "../client/sanity";

const ArticlePage = ({ title, author, lastUpdated, content, seoDesc, articleImg }) => {
  const theme = useTheme();
  const [recentArticles, setRecentArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  const date = new Date(lastUpdated);
  const options = { year: "numeric", month: "short", day: "2-digit" };
  lastUpdated = new Intl.DateTimeFormat("en-US", options).format(date);

  useEffect(() => {
    const fetchRecentArticles = async () => {
      try {
        // Get current URL path
        const currentPath = window.location.pathname;
        const currentSlug = currentPath.split("/").pop();

        const data = await createClient.fetch(
          `*[_type == "article" && articleType == "BlogPost" && slug.current != $currentSlug] | order(_createdAt desc)[0...5]{
            "imageUrl": image.asset->url,
            "title": headline,
            "date": _createdAt,
            "description": seoDescription,
            "slug": slug.current
          }`,
          { currentSlug }
        );
        setRecentArticles(data);
        setLoading(false);
      } catch (err) {
        // setError("Error fetching recent articles");
        setLoading(false);
      }
    };

    fetchRecentArticles();
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${bgUrl})`,
        paddingBottom: 5,
      }}
    >
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={seoDesc} />
        <meta name="author" content={author} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={seoDesc} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={articleImg} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={seoDesc} />
        <meta name="twitter:image" content={articleImg} />
      </Helmet>
      <Box position={"relative"} paddingTop={5}>
        <Container sx={{ maxWidth: "1400px !important" }}>
          <Stack
            position={"relative"}
            spacing={4}
            direction={"column"}
            sx={{ borderBottom: "2px solid #9DA8BC", pb: 10 }}
          >
            <header>
              <Stack spacing={2}>
                <Typography
                  color={theme.palette.text.header}
                  whiteSpace={"normal"}
                  variant="h3"
                  component={"h1"}
                  sx={{
                    lineHeight: 1.2,
                    textTransform: "capitalize",
                  }}
                >
                  <Box position={"relative"}>
                    {title.split(" ").slice(0, -2).join(" ")}{" "}
                    <Box component={"span"} sx={{ color: "#EA552B" }}>
                      {title.split(" ").slice(-2).join(" ")}
                    </Box>
                    <Box
                      component={"img"}
                      src={letterHighlight}
                      alt={"decorative highlight"}
                      sx={{ position: "absolute", top: "-55px", left: "-40px" }}
                      loading="lazy"
                    />
                  </Box>
                </Typography>
              </Stack>
            </header>
          </Stack>
          <Box
            display={"flex"}
            sx={{
              flexDirection: { xs: "column", lg: "row" },
              gap: 5,
              mt: 6,
              color: theme.palette.text.description2,
            }}
          >
            <Box
              component={"article"}
              sx={{
                width: { xs: "100%", lg: "60%" },
              }}
            >
              <PortableTextComponent props={content} />
            </Box>
            <Box
              sx={{
                width: { xs: "100%", lg: "40%" },
              }}
            >
              {loading ? (
                <>
                  <Skeleton variant="rectangular" width="100%" height={200} />
                  <Skeleton width="60%" />
                  <Skeleton width="80%" />
                </>
              ) : (
                <SimilarStoriesCard articles={recentArticles} />
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ArticlePage;
