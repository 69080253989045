import { Box } from "@mui/material";
import ArticlePage from "./ArticlePage";
import { Main } from "layouts";
import React from "react";

const Article = (context) => {
  const article = context.pageContext.edge;
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main bgcolor={"background.paper"}>
        <ArticlePage
          title={article.headline}
          author={article.author}
          lastUpdated={article._updatedAt}
          content={article._rawDescription}
          articleImg={article.image.asset.url}
          seoDesc={article.seoDescription}
        />
      </Main>
    </Box>
  );
};

export default Article;
