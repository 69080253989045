export const light = {
  alternate: {
    main: "#f7faff",
    dark: "#edf1f7",
  },
  cardShadow: "rgba(23, 70, 161, .11)",
  mode: "light",
  primary: {
    main: "#2984FF",
    blackFade: "#171717",
    light: "#467de3",
    dark: "#2f6ad9",
    contrastText: "#FFF",
    buttonColor: "#2984FF",
    textColor: "#FFF",
    hoverColor: "#2f6ad9",
  },
  secondary: {
    light: "#ffb74d",
    main: "#f9b934",
    dark: "#FF9800",
    contrastText: "rgba(0, 0, 0, 0.87)",
    buttonColor: "#28303C",
    textColor: "#FFF",
    hoverColor: "#28303C",
  },
  tertiary: {
    buttonColor: "transparent",
    textColor: "#2984FF",
  },
  disabled: {
    buttonColor: "rgba(0, 0, 0, 0.12)",
    textColor: "rgba(0, 0, 0, 0.26)",
  },
  text: {
    primary: "#2984FF",
    primary2: "#0085FF",
    secondary: "#677788",
    light: "#FFFFFF",
    error: "#EA552B",
    header: "#28303C",
    header2: "#34344B",
    description: "#7F7B9D",
    description2: "#28303C",
    body1: "#80808F",
    radioBtn: "#28303C",
    pricing: "#13152B",
    caption: "#9DA8BC",
    primaryBlue: "#2C85FF",
    highlight: "#EA552B",
  },
  border: {
    border1: "#C8DFFF",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  background: {
    paper: "#ffffff",
    default: "#ffffff",
    level2: "#f5f5f5",
    level1: "#ffffff",
  },
  fontFamily: {
    medium: "Geometria-Medium",
  },
};

export const dark = {
  alternate: {
    main: "#1a2138",
    dark: "#151a30",
  },
  cardShadow: "rgba(0, 0, 0, .11)",
  common: {
    black: "#000",
    white: "#fff",
  },
  mode: "dark",
  primary: {
    main: "#1976d2",
    light: "#2196f3",
    dark: "#0d47a1",
    contrastText: "#fff",
  },
  secondary: {
    light: "#FFEA41",
    main: "#FFE102",
    dark: "#DBBE01",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    primary: "#EEEEEF",
    secondary: "#AEB0B4",
  },
  divider: "rgba(255, 255, 255, 0.12)",
  background: {
    paper: "#222B45",
    default: "#222B45",
    level2: "#333",
    level1: "#2D3748",
  },
};
