import { Box, Card, CardContent, Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import ReusableButton from "./ReusableButton";

const SimilarStoriesCard = ({ articles }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        py: "22px",
        px: "23px",
        borderRadius: "12px",
        outline: "0.94px solid #F5F5F7",
        "&.MuiCard-root": {
          backgroundColor: `#ffffff80`,
          boxShadow: "none",
        },
        height: "fit-content",
      }}
    >
      {/* heading */}
      <Typography variant="title1">Similar Stories</Typography>

      {/* blog cards */}
      {articles?.length === 0 ? (
        <Typography>No recent articles available.</Typography>
      ) : (
        <Grid container spacing={2} mt={"22px"}>
          {articles?.map((article, index) => {
            return (
              <Grid key={index} item xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    borderRadius: "20px",
                    border: `1px solid ${theme.palette.text.primaryBlue}42`,
                    boxShadow: "none",
                    gap: "15px",
                    padding: "18px 11px",
                  }}
                >
                  {/* blog text contents */}
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                      padding: { sm: "0 15px 0 0 !important" },
                    }}
                  >
                    <Box
                      component={"img"}
                      src={article?.imageUrl}
                      alt={article?.title}
                      sx={{ borderRadius: "10px", width: "auto" }}
                      loading="lazy"
                      height={125}
                    />
                    <Stack gap={"6px"}>
                      <Typography
                        sx={{
                          fontSize: "17px",
                          fontWeight: "600",
                          textDecoration: "none",
                          color: theme.palette.primary.blackFade,
                        }}
                      >
                        {article?.title}
                      </Typography>
                      <ReusableButton
                        variant="tertiary"
                        title="Read More"
                        link={`/blogs/${article?.slug}`}
                        sx={{ width: "fit-content" }}
                        aria-label={`Read more about ${article?.title}`}
                        component="a"
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Card>
  );
};

export default SimilarStoriesCard;
