import React from "react";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Main from "layouts/Main";
import ReusableButton from "components/ReusableButton";

const ErrorPage = () => {
  return (
    <>
      <Helmet>
        <title>Appnigma AI</title>
        <meta name="description" content="Generate code to bridge Salesforce & other apps, powered by AI" />
        <meta property="og:title" content="Appnigma AI" />
        <meta property="og:description" content="Generate code to bridge Salesforce & other apps, powered by AI" />
        <meta property="og:image" content="src/images/appnigma_full_logo.webp" />
        <meta property="og:url" content={`https://www.appnigma.ai/`} />
      </Helmet>
      <Main>
        <Box
          sx={{
            width: 1,
            height: 1,
            overflow: "hidden",
          }}
        >
          <Container>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              minHeight={"calc(100vh - 58px)"}
            >
              <Typography variant="h1" component={"h1"} align={"center"} sx={{ fontWeight: 700 }}>
                500
              </Typography>
              <Typography variant="h6" component="p" color="text.secondary" align={"center"}>
                Oops! Something went wrong on our end.
              </Typography>
              <Box marginTop={4}>
                <ReusableButton title={"Back home"} link={"/"} />
              </Box>
            </Box>
          </Container>
        </Box>
      </Main>
    </>
  );
};

export default ErrorPage;
