import React from "react";
import Demo from "components/Demo";
import { Helmet } from "react-helmet";

const DemoPage = () => {
  return (
    <>
      <Helmet>
        <title>Appnigma AI</title>
        <meta name="description" content="Generate code to bridge Salesforce & other apps, powered by AI" />
        <meta property="og:title" content="Appnigma AI" />
        <meta property="og:description" content="Generate code to bridge Salesforce & other apps, powered by AI" />
        <meta property="og:image" content="src/images/appnigma_full_logo.webp" />
        <meta property="og:url" content={`https://www.appnigma.ai/`} />
        <meta name="twitter:title" content="Appnigma AI" />
        <meta name="twitter:description" content="Generate code to bridge Salesforce & other apps, powered by AI" />
        <meta name="twitter:image" content="src/images/appnigma_full_logo.webp" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Demo />
    </>
  );
};

export default DemoPage;
